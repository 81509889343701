
import InvestmentTagChip from '@/modules/investments/components/settings/tags/tag.vue';
import { costStatuses } from '@/shared/config/costs';
import { parsePrice } from '@/shared/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component({
    components: {
        InvestmentTagChip,
    },
})
export default class CostRow extends Vue {
    @Prop(Object) public item!: any;
    @Prop(Array) public itemsHeaders!: any;

    public parsePrice = parsePrice;

    get cost() {
        return this.item;
    }
    get costsHeaders() {
        return this.itemsHeaders;
    }

    public getCostStatus(id: string) {
        return costStatuses.find((e) => e.id === id);
    }
}
